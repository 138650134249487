import React from 'react'
import PropTypes from "prop-types";
// style
import './basicTitle.css'

const BasicTitle = ({ title, colorText, colorSpan }) => (
  <div className="bt-container">
    <span className={`bg-${colorSpan} bt-span-left`} />
    <h3 className={`${colorText} bt-title`}>{title}</h3>
    <span className={`bg-${colorSpan} bt-span-right`} />
  </div>
)

BasicTitle.propTypes = {
  title: PropTypes.string.isRequired,
  colorText: PropTypes.string.isRequired,
  colorSpan: PropTypes.string.isRequired,
}
export default BasicTitle
