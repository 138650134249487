import * as React from "react"
import { Link } from "gatsby"
// components
import BasicTitle from "../components/basicTitle/basicTitle";
// style
import '../styles/404Page.css'

const NotFoundPage = () => {
  return (
    <main>
      <div className="not-found-container">
        <h1 className="pb-32 primary">Page introuvable</h1>
        <h2 className="secondary">Désolé <span role="img" aria-label="smiley">😔</span></h2>
        <BasicTitle
          colorSpan="primary"
          colorText="secondary"
          title="Nous n'avons pas pu trouver ce que vous cherchiez"
        />
        <Link className="not-found-link" to="/">Retrouvez votre chemin ici</Link>
      </div>
    </main>

  )
}

export default NotFoundPage
